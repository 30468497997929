@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

.tab-list-no-mb {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
}

.ant-collapse-border-light {
  .ant-collapse-item {
    border-bottom: 1px solid #e5e7eb;
  }

  .ant-collapse-content {
    border-top: 1px solid #e5e7eb;
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.72);
  }
}

.ant-spin-fullscreen {
  background-color: rgba(255, 255, 255, 0.72);
}

